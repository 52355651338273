var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-top":"20px"}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8}},[_c('h3',{staticClass:"title"},[_vm._v("工商信息")]),_c('a-form-item',{attrs:{"label":"企业全称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
              normalize: this.$lodash.trim,
              rules: [{
                required: true, message: '请输入企业全称'
              }]
            }]),expression:"['name', {\n              normalize: this.$lodash.trim,\n              rules: [{\n                required: true, message: '请输入企业全称'\n              }]\n            }]"}],attrs:{"placeholder":"请输入企业全称"}})],1),_c('a-form-item',{attrs:{"label":"工商营业执照注册号"}},[_c('div',{attrs:{"slot":"extra"},slot:"extra"},[_c('span',{staticClass:"tip-text"},[_vm._v("请输入15位工商营业执照注册号，或三证合一后18位的统一社会信用代码 ")]),_c('a-popover',{attrs:{"placement":"bottom"}},[_c('a',{staticClass:"tip-text"},[_vm._v("查看示例")]),_c('template',{slot:"content"},[_c('img',{staticStyle:{"width":"400px"},attrs:{"src":require("@/assets/images/license_example.png")}})])],2)],1),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['registration_no', {
              rules:[{pattern: /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/, message: '工商营业执照注册号输入错误'}]
            }]),expression:"['registration_no', {\n              rules:[{pattern: /^([0-9A-HJ-NPQRTUWXY]{2}\\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\\d{14})$/, message: '工商营业执照注册号输入错误'}]\n            }]"}]})],1),_c('a-form-item',{attrs:{"label":"企业工商营业执照","required":""}},[_c('span',{staticClass:"tip-text",attrs:{"slot":"extra"},slot:"extra"},[_vm._v("原件照片、扫描件或者加盖公章的复印件，支持.jpg .jpeg .bmp .png格式照片，大小不超过10M")]),_c('upload-image',{directives:[{name:"decorator",rawName:"v-decorator",value:(['business_license', {rules: [
              { validator: _vm.checkBusinessLicense }
            ]
            }]),expression:"['business_license', {rules: [\n              { validator: checkBusinessLicense }\n            ]\n            }]"}],attrs:{"is-add-watermark":_vm.isAddWatermark,"accept":_vm.accept},on:{"change":_vm.handleBusinessLicenseChange}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('h3',{staticClass:"title"},[_vm._v("法人信息")]),_c('a-form-item',{attrs:{"label":"法人姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['legal_name',{
              normalize: this.$lodash.trim,
              rules:[
                { required: true, message: '请输入法人姓名' }
              ]
            }]),expression:"['legal_name',{\n              normalize: this.$lodash.trim,\n              rules:[\n                { required: true, message: '请输入法人姓名' }\n              ]\n            }]"}],attrs:{"placeholder":"请输入法人姓名"}})],1),_c('a-form-item',{attrs:{"label":"法人身份证号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['identity_card',{
              rules :[
                { pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9X]$/,
                  message: '法人身份证号输入错误' }
              ]
            }]),expression:"['identity_card',{\n              rules :[\n                { pattern: /^[1-9]\\d{5}(18|19|([23]\\d))\\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\\d{3}[0-9X]$/,\n                  message: '法人身份证号输入错误' }\n              ]\n            }]"}],attrs:{"placeholder":"请输入法人身份证号"}})],1),_c('a-form-item',{attrs:{"label":"法人身份证照片(正面)"}},[_c('span',{staticClass:"tip-text",attrs:{"slot":"extra"},slot:"extra"},[_vm._v("支持.jpg .jpeg .bmp .png格式照片，大小不超过10M")]),_c('upload-image',{directives:[{name:"decorator",rawName:"v-decorator",value:(['front_identity_card',{
              rules:[
                {validator: _vm.checkFrontIdentityCard}
              ]
            }]),expression:"['front_identity_card',{\n              rules:[\n                {validator: checkFrontIdentityCard}\n              ]\n            }]"}],attrs:{"is-add-watermark":_vm.isAddWatermark,"accept":_vm.accept},on:{"change":_vm.handleFrontIdentityCard}})],1),_c('a-form-item',{attrs:{"label":"法人身份证照片(反面)"}},[_c('span',{staticClass:"tip-text",attrs:{"slot":"extra"},slot:"extra"},[_vm._v("支持.jpg .jpeg .bmp .png格式照片，大小不超过10M")]),_c('upload-image',{directives:[{name:"decorator",rawName:"v-decorator",value:(['back_identity_card',{
              rules:[
                {validator: _vm.checkBackIdentityCard}
              ]
            }]),expression:"['back_identity_card',{\n              rules:[\n                {validator: checkBackIdentityCard}\n              ]\n            }]"}],attrs:{"is-add-watermark":_vm.isAddWatermark,"accept":_vm.accept},on:{"change":_vm.handleBackIdentityCard}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('h3',{staticClass:"title"},[_vm._v("设备信息")]),_c('a-form-item',{attrs:{"label":"终端设备名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['device_name']),expression:"['device_name']"}],attrs:{"placeholder":"请输入终端设备名称"}})],1),_c('a-form-item',{attrs:{"label":"终端设备照片"}},[_c('div',{attrs:{"slot":"extra"},slot:"extra"},[_c('span',{staticClass:"tip-text"},[_vm._v("支持.jpg .jpeg .bmp .png格式照片，大小不超过10M；最多4张 ")]),_c('a-popover',{attrs:{"placement":"bottom"}},[_c('a',{staticClass:"tip-text"},[_vm._v("查看示例")]),_c('template',{slot:"content"},[_c('img',{staticStyle:{"width":"300px"},attrs:{"src":require("@/assets/images/device_example.png")}})])],2)],1),_c('upload-image',{directives:[{name:"decorator",rawName:"v-decorator",value:(['devices',{
              rules:[
                {validator: _vm.checkDevices}
              ]
            }]),expression:"['devices',{\n              rules:[\n                {validator: checkDevices}\n              ]\n            }]"}],attrs:{"is-add-watermark":_vm.isAddWatermark,"accept":_vm.accept,"max-count":4},on:{"change":_vm.handleDevices}})],1)],1)],1),_c('a-row',{attrs:{"type":"flex","justify":"center"}},[_c('a-col',[_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.submitting}},[_vm._v(" 提交 ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }